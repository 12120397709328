import React, { useEffect, useState } from 'react';
import qs from 'query-string';

import { QUERY_PARAMS } from '../Callback/Constants';
import Spinner from '../Spinner';
import Error from '../Error';
import { URLS } from '../../constants';

const Auth = () => {
  const [error, setError] = useState(false);

  useEffect(() => {
    const queryParams = qs.parse(window.location.search);
    const requestId = queryParams[QUERY_PARAMS.REQUEST_ID];
    const bankId = queryParams[QUERY_PARAMS.BANK_ID];

    if (requestId && bankId) {
      window.location.replace(
        `${URLS.AUTHENTICATE}?request-id=${requestId}&bank-id=${bankId}`,
      );
    } else {
      setError(true);
    }
  }, []);

  return (
    <>
      {!error && <Spinner />}
      {error && <Error showGoBackButton />}
    </>
  );
};

export default Auth;
