import React from 'react';
import { withTranslation } from 'react-i18next';
import { Body, Headline, spacing } from '@sumup/circuit-ui';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ReactComponent as Image } from '../../../assets/error.svg';

const CenteredImage = styled(Image)(
  ({ theme }) => css`
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${theme.spacings.mega};
  `,
);

const NotEnrolled = ({ t }) => {
  return (
    <>
      <CenteredImage />
      <Headline size="three">{t('enable_business_account.title')}</Headline>
      <Body css={spacing({ top: 'mega', bottom: 'mega' })}>
        {t('enable_business_account.subtitle')}
      </Body>
    </>
  );
};

NotEnrolled.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(NotEnrolled);
