import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../context/auth';

const Private = ({ component: Component, redirectTo, ...rest }) => {
  const { sessionData } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        sessionData ? <Component {...props} /> : <Redirect to={redirectTo} />
      }
    />
  );
};

Private.propTypes = {
  component: PropTypes.func.isRequired,
  redirectTo: PropTypes.string.isRequired,
};

export default Private;
