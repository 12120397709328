import React from 'react';
import { withTranslation } from 'react-i18next';
import { Body, Headline, spacing } from '@sumup/circuit-ui';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ReactComponent as Image } from '../../../assets/settings.svg';

const CenteredImage = styled(Image)`
  ${() => css`
    margin-left: auto;
    margin-right: auto;
  `};
`;

const ScaNotEnabled = ({ t }) => {
  return (
    <>
      <CenteredImage />
      <Headline size="three">{t('enable_mfa.title')}</Headline>
      <Body css={spacing({ top: 'mega', bottom: 'mega' })}>
        {t('enable_mfa.subtitle')}
      </Body>
    </>
  );
};

ScaNotEnabled.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ScaNotEnabled);
