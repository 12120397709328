import React from 'react';
import { withTranslation } from 'react-i18next';
import { Body, Headline, Toggle, spacing } from '@sumup/circuit-ui';
import { map } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useAuth } from '../../../context/auth';

const Permissions = ({ t, preferences, setPreferences }) => {
  const {
    sessionData: { tppName, account },
  } = useAuth();
  const { accountInfo, balanceTxHistory } = preferences;

  const accessToggles = (translate) => [
    {
      label: translate('accounts.account_information'),
      method: () => {
        setPreferences({
          ...preferences,
          accountInfo: !accountInfo,
        });
      },
      isChecked: accountInfo,
      id: 'account-info',
    },
    {
      label: translate('accounts.balance_transaction_history'),
      method: () => {
        setPreferences({
          ...preferences,
          balanceTxHistory: !balanceTxHistory,
        });
      },
      isChecked: balanceTxHistory,
      id: 'balance-transaction-history',
    },
  ];

  return (
    <>
      <Headline size="three">{t('accounts.enable_settings')}</Headline>
      <Body css={spacing({ top: 'mega', bottom: 'mega' })}>
        {t('accounts.permissions_prompt', {
          providerName: tppName,
          sumup_account: account.accountName,
          interpolation: { escapeValue: false },
        })}
      </Body>
      <Body css={spacing({ bottom: 'byte' })}>
        {t('accounts.access_heading')}
      </Body>
      <Body css={spacing({ bottom: 'kilo' })}>
        {map(
          ({ label, method, isChecked, id }) => (
            <Toggle
              checked={isChecked}
              checkedLabel="on"
              uncheckedLabel="off"
              onChange={() => {
                method(!isChecked);
              }}
              label={label}
              css={spacing({ bottom: 'byte' })}
              key={id}
            />
          ),
          accessToggles(t),
        )}
      </Body>
    </>
  );
};

Permissions.propTypes = {
  t: PropTypes.func.isRequired,
  preferences: PropTypes.shape({
    accountInfo: PropTypes.bool.isRequired,
    balanceTxHistory: PropTypes.bool.isRequired,
  }).isRequired,
  setPreferences: PropTypes.func.isRequired,
};

export default withTranslation()(Permissions);
