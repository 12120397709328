import React, { useState, useCallback } from 'react';
import {
  NotificationInline,
  Body,
  ButtonGroup,
  Headline,
  Card,
  spacing,
} from '@sumup/circuit-ui';
import { formatCurrency } from '@sumup/intl';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { createConsent, cancelConsent } from '../../requests';
import { useAuth } from '../../context/auth';
import { REQUEST_STATUSES } from '../../constants';

const StyledBody = styled(Body)(
  ({ theme }) => css`
    strong {
      font-weight: ${theme.fontWeight.bold};
    }
  `,
);

const Payments = ({ t }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { sessionData } = useAuth();
  const {
    requestId,
    preferences,
    accessToken,
    tokenCallback,
    transfer: paymentData,
  } = sessionData;

  const checkForErrors = useCallback(() => {
    if (!requestId || !accessToken) {
      setLoading(false);
      setErrorMessage(t('login.errors.invalid_request_id_access_token'));
    }
  }, [requestId, accessToken, t]);

  const handleApprove = () => {
    setLoading(true);
    checkForErrors();

    createConsent({ requestId, accessToken, preferences }).then(
      ({ status, data }) => {
        setLoading(false);
        if (status === REQUEST_STATUSES.success) {
          const callback = tokenCallback || data.tppCallback;
          window.location.assign(`${callback}?request-id=${requestId}`);
        }
      },
    );
  };

  const handleCancel = () => {
    setLoading(true);
    checkForErrors();

    cancelConsent({ requestId }).then(({ status, data }) => {
      setLoading(false);
      if (status === REQUEST_STATUSES.success) {
        const callback = tokenCallback || data.tppCallback;
        window.location.assign(`${callback}?request-id=${data.requestId}`);
      }
    });
  };

  return (
    <>
      <Card>
        {errorMessage && (
          <NotificationInline body={errorMessage} variant="danger" />
        )}
        <Headline size="two" as="h1">
          {t('payments.payment_information')}
        </Headline>
        <StyledBody css={spacing({ top: 'mega', bottom: 'kilo' })}>
          {t('payments.account_number')}{' '}
          <strong>{paymentData.accountNumber}</strong>
        </StyledBody>
        <StyledBody css={spacing({ bottom: 'kilo' })}>
          {t('payments.payment_referrence')}{' '}
          <strong>{paymentData.reference}</strong>
        </StyledBody>
        <StyledBody css={spacing({ bottom: 'kilo' })}>
          {t('payments.amount')}{' '}
          <strong>
            {formatCurrency(
              paymentData.amount / 100,
              'en-GB',
              paymentData.currency,
            )}
          </strong>
        </StyledBody>
        <StyledBody css={spacing({ bottom: 'kilo' })}>
          {t('payments.beneficiary')} <strong>{paymentData.beneficiary}</strong>
        </StyledBody>
        <Body css={spacing({ bottom: 'kilo' })}>
          {t('payments.confirm_prompt')}
        </Body>
        <ButtonGroup
          align="center"
          actions={{
            secondary: {
              children: t('common.cancel'),
              onClick: handleCancel,
            },
            primary: {
              children: t('common.approve'),
              onClick: handleApprove,
              isLoading: loading,
              loadingLabel: t('common.approve'),
            },
          }}
        />
      </Card>
    </>
  );
};

Payments.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Payments;
