export const URLS = {
  AUTHENTICATE: '/authentication',
  CONSENT_REQUESTS: '/consent-requests',
  CONSENTS: '/consents',
};

export const ROUTES = {
  HOME: '/',
  CONSENTS: '/consents',
  CALLBACK: '/auth/callback',
  PAYMENTS: '/payments',
};

export const REQUEST_STATUSES = {
  success: 200,
  unauthorized: 401,
  serverError: 500,
};
