import React from 'react';
import { withTranslation } from 'react-i18next';
import { Body, Headline, spacing } from '@sumup/circuit-ui';
import { formatCurrency } from '@sumup/intl';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useAuth } from '../../../context/auth';

const StyledBody = styled(Body)(
  ({ theme }) => css`
    strong {
      font-weight: ${theme.fontWeight.bold};
    }
  `,
);

const Payment = ({ t }) => {
  const {
    sessionData: { account },
  } = useAuth();

  return (
    <>
      <Headline size="two" as="h1">
        {t('accounts.payment_initiation')}
      </Headline>
      <Body css={spacing({ top: 'mega', bottom: 'mega' })}>
        {t('accounts.payment_prompt')}
      </Body>
      <Body variant="highlight">{t('accounts.access_heading')}</Body>
      <StyledBody css={spacing({ bottom: 'mega' })}>
        {account.accountName}{' '}
        <strong>
          {formatCurrency(account.balance / 100, 'en-GB', account.currency)}
        </strong>
      </StyledBody>
    </>
  );
};

Payment.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Payment);
