import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const getBrowserLocale = () =>
  window.navigator.language ||
  window.navigator.userLanguage ||
  window.navigator.browserLanguage ||
  window.navigator.systemLanguage;

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: getBrowserLocale(),
    backend: {
      loadPath: `/translations/{{lng}}.json`,
    },
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  });

export default i18n;
