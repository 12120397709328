import React from 'react';
import { withTranslation } from 'react-i18next';
import { Body, Headline, spacing } from '@sumup/circuit-ui';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import PendingImage from '../../../assets/pending.png';

const StyledImage = styled.img`
  max-width: 70%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
`;

const NotVerified = ({ t }) => {
  return (
    <>
      <StyledImage src={PendingImage} alt="Not verified" />
      <Headline size="three">{t('verification.pending.title')}</Headline>
      <Body css={spacing({ top: 'mega', bottom: 'mega' })}>
        {t('verification.pending.subtitle')}
      </Body>
    </>
  );
};

NotVerified.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(NotVerified);
