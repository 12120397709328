import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Spinner as CircuitSpinner } from '@sumup/circuit-ui';

const StyledSpinner = styled(CircuitSpinner)`
  ${() => css`
    color: var(--cui-fg-accent);
  `};
`;

const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    padding-top: ${theme.spacings.peta};
    padding-bottom: ${theme.spacings.peta};
  `};
`;

const Spinner = () => {
  return (
    <Container>
      <StyledSpinner size="giga" />
    </Container>
  );
};

export default Spinner;
