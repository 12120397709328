import React, { useState, Suspense } from 'react';
import './services/i18n';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from '@emotion/styled';
import { css, ThemeProvider } from '@emotion/react';
import { light } from '@sumup/design-tokens';
import { BaseStyles } from '@sumup/circuit-ui';

import { AuthContext } from './context/auth';
import Accounts from './components/Accounts';
import Payments from './components/Payments';
import Auth from './components/Auth';
import Callback from './components/Callback';
import Private from './routes/Private';
import Public from './routes/Public';
import { ReactComponent as LogoIcon } from './assets/logo.svg';
import { ROUTES } from './constants';

const Logo = styled(LogoIcon)`
  ${({ theme }) => css`
    display: block;
    fill: ${theme.colors.white};
    margin-bottom: ${theme.spacings.peta};
    margin-top: ${theme.spacings.peta};
  `};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin: 0 auto;
  max-width: 480px;
`;

const App = () => {
  const [sessionData, setSessionData] = useState(null);

  return (
    <AuthContext.Provider value={{ sessionData, setSessionData }}>
      <ThemeProvider theme={light}>
        <BaseStyles />
        <Container>
          <Logo data-testid="sumup-logo" />
          <Router>
            <Suspense fallback={null}>
              <Public
                path={ROUTES.HOME}
                redirectTo={ROUTES.CONSENTS}
                component={Auth}
                exact
              />
              <Public
                path={ROUTES.CALLBACK}
                redirectTo={ROUTES.CONSENTS}
                component={Callback}
                exact
              />
              <Private
                path={ROUTES.CONSENTS}
                redirectTo={ROUTES.HOME}
                component={Accounts}
              />
              <Private
                path={ROUTES.PAYMENTS}
                redirectTo={ROUTES.HOME}
                component={Payments}
              />
            </Suspense>
          </Router>
        </Container>
      </ThemeProvider>
    </AuthContext.Provider>
  );
};

export default App;
