import axios from 'axios';
import { URLS } from '../constants';

export const createConsent = ({ requestId, accessToken, preferences }) => {
  return axios.post(`${URLS.CONSENTS}/${requestId}`, preferences, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const cancelConsent = ({ requestId }) =>
  axios.delete(`${URLS.CONSENTS}/${requestId}`);

export const requestConsent = (params) =>
  axios.post(URLS.CONSENT_REQUESTS, params);
