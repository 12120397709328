import React from 'react';
import { NotificationFullscreen } from '@sumup/circuit-ui';
import PropTypes from 'prop-types';
import { ReactComponent as Image } from '../../assets/general-error.svg';

const Error = ({ showGoBackButton }) => {
  const actions = showGoBackButton
    ? {
        primary: {
          children: 'Go back',
          onClick: function goBack() {
            window.history.back();
          },
        },
      }
    : null;

  return (
    <NotificationFullscreen
      headline={{
        as: 'h1',
        label: 'Something went wrong',
      }}
      body="Sorry, there was a problem with your request. Please go back to the merchant website and try again."
      image={{
        svg: Image,
        alt: '',
      }}
      actions={actions}
    />
  );
};

Error.propTypes = {
  showGoBackButton: PropTypes.bool,
};

Error.defaultProps = {
  showGoBackButton: false,
};

export default Error;
